import { VisuallyHidden, chakra, useMultiStyleConfig } from "@chakra-ui/react";
import throttle from "lodash/throttle";
import { useEffect, useMemo, useState } from "react";
import { InView as IntersectionObserver } from "react-intersection-observer";
import { useEditorTools } from "src/components/EditorTools/EditorTools";
import { HeadingLevelBoundary } from "src/components/Heading/Heading";
import { ScrollButton } from "src/components/ScrollButton/ScrollButton";
import {
	SectionNavigator,
	SectionNavigatorContext,
} from "src/components/SectionNavigator/SectionNavigator";
import {
	findValueByKeyRecursive,
	textToFragmentId,
	truthy,
} from "src/lib/utils";
import {
	renderSection,
	Section,
	BubbleContainerContext,
} from "./renderSections";

const enhance = (sections: Array<Section>, startIndex = 0) => {
	return sections
		.map((section, initialIndex) => {
			const index = initialIndex + startIndex;
			const sectionAnchorOverride = getSectionAnchorOverride(section);

			if (sectionAnchorOverride) {
				return {
					section,
					...sectionAnchorOverride,
				};
			}

			const title = findValueByKeyRecursive(section, "title");
			const fallbackTitle = `${section.__typename.replace(
				"Record",
				"",
			)} ${index}`;

			const anchorId = textToFragmentId(title ?? fallbackTitle);

			const isBubble = BubbleContainerTypes.includes(section.__typename);
			const lastSection =
				index > 0 ? sections[index - 1]?.__typename : undefined;
			const nextSection =
				index < sections.length - 1
					? sections[index + 1]?.__typename
					: undefined;
			const isFirstBubble =
				isBubble && !BubbleContainerTypes.includes(lastSection);
			const isLastBubble =
				isBubble && !BubbleContainerTypes.includes(nextSection);

			return {
				section,
				anchorId,
				title,
				isFirstBubble,
				isLastBubble,
			};
		})
		.filter(truthy);
};

const useEnhance = (sections: SectionRendererProps["sections"]) => {
	return useMemo(() => {
		return Array.isArray(sections)
			? enhance(sections)
			: {
					enhancedTopSections: enhance(sections.topSections),
					enhancedBottomSections: enhance(
						sections.bottomSections,
						sections.topSections.length,
					),
				};
	}, [sections]);
};

const EnhancedSections: React.FC<{
	setLatestSection: (anchorId: string) => void;
	enhancedSections: Array<{
		section: Section;
		anchorId: string;
		title?: string;
		isFirstBubble?: boolean;
		isLastBubble?: boolean;
	}>;
}> = ({ setLatestSection, enhancedSections }) => {
	const styles = useMultiStyleConfig("SectionRenderer");
	const { isEnabled: editorToolsEnabled } = useEditorTools();

	return enhancedSections.map(
		({ section, anchorId, isFirstBubble, isLastBubble }) => {
			return "id" in section ? (
				<IntersectionObserver
					key={section.id}
					rootMargin="-50% 0px -50% 0px"
					delay={100}
					onChange={(inView) => inView && setLatestSection(anchorId)}
				>
					<VisuallyHidden
						as="div"
						position="relative"
						id={anchorId}
						// height of Header
						top="calc(var(--chakra-space-28) * -1)"
					/>
					<BubbleContainerContext.Provider
						value={{
							isFirst: isFirstBubble ?? false,
							isLast: isLastBubble ?? false,
						}}
					>
						{editorToolsEnabled && (
							<chakra.div __css={styles.jumpLinkWrapper}>
								<chakra.div __css={styles.jumpLink}>
									{`#${anchorId}`}
								</chakra.div>
							</chakra.div>
						)}
						{renderSection(section, anchorId)}
					</BubbleContainerContext.Provider>
				</IntersectionObserver>
			) : null;
		},
	);
};

const getSectionAnchorOverride = (
	section: Section,
): { anchorId: string; title: string } | null => {
	switch (section.__typename) {
		// We need a deterministic id for the newsletter section as we link to it in various places (CMS, Code).
		case "NewsletterSignupRecord":
			return {
				anchorId: "newsletter-signup",
				title: "Newsletter Signup",
			};
		default:
			return null;
	}
};

const BubbleContainerTypes: Array<Section["__typename"] | undefined> = [
	"FlipperSectionRecord",
	"FunctionalityCollectionRecord",
];

export type SectionRendererProps = {
	sections:
		| Array<Section>
		| {
				topSections: Array<Section>;
				bottomSections: Array<Section>;
		  };
	showNavigator?: boolean;
	children?: React.ReactNode;
};

export const SectionRenderer: React.FC<SectionRendererProps> = ({
	sections,
	showNavigator = true,
	children,
}) => {
	const styles = useMultiStyleConfig("SectionRenderer");
	const [latestSection, setLatestSection] = useState<string | undefined>(
		undefined,
	);

	const [showScrollButton, setShowScrollButton] = useState(false);

	const handleScroll = useMemo(
		() =>
			throttle(() => {
				const scrollPosition = window.scrollY;
				const halfWindowHeight =
					document.documentElement.scrollHeight / 2;

				setShowScrollButton(scrollPosition > halfWindowHeight);
			}, 1000),
		[],
	);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, [handleScroll]);

	const enhanced = useEnhance(sections);

	const enhancedSections = Array.isArray(enhanced)
		? enhanced
		: [...enhanced.enhancedTopSections, ...enhanced.enhancedBottomSections];

	return (
		<SectionNavigatorContext.Provider
			value={{
				currentSectionId: latestSection,
				allSections: enhancedSections,
			}}
		>
			{showScrollButton && <ScrollButton sx={styles.scrollButton} />}
			{showNavigator && <SectionNavigator sections={enhancedSections} />}
			{"enhancedTopSections" in enhanced ? (
				<>
					<HeadingLevelBoundary>
						<EnhancedSections
							enhancedSections={enhanced.enhancedTopSections}
							setLatestSection={setLatestSection}
						/>
					</HeadingLevelBoundary>
					{children}
					<HeadingLevelBoundary>
						<EnhancedSections
							enhancedSections={enhanced.enhancedBottomSections}
							setLatestSection={setLatestSection}
						/>
					</HeadingLevelBoundary>
				</>
			) : (
				<HeadingLevelBoundary>
					<EnhancedSections
						enhancedSections={enhanced}
						setLatestSection={setLatestSection}
					/>
				</HeadingLevelBoundary>
			)}
		</SectionNavigatorContext.Provider>
	);
};

// 🔬 TBD: Please evaluate
