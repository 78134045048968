import {
	chakra,
	Container,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { FAQPageJsonLd } from "next-seo";
import React from "react";
import { FaqsFragment, Maybe } from "src/__generated__/cms-schema.codegen";
import { DatoRichTextContent } from "src/components/Dato/DatoRichTextContent";
import {
	FaqAccordion,
	FaqAccordionItem,
} from "src/components/FaqAccordion/FaqAccordion";
import { Hx } from "src/components/Heading/Heading";

type FaqSectionProps = {
	title: Maybe<string>;
	subtitle: Maybe<string>;
	faqs: Array<FaqsFragment>;
};

const QuestionsAndAnswers: React.FC<{ faq: FaqsFragment }> = ({ faq }) => (
	<FaqAccordion>
		{faq.questions.map(({ id, question, answer }) => (
			<FaqAccordionItem key={id} label={question}>
				<DatoRichTextContent html={answer} />
			</FaqAccordionItem>
		))}
	</FaqAccordion>
);

export const FaqSection: React.FC<FaqSectionProps> = ({
	title,
	subtitle,
	faqs,
}) => {
	const styles = useMultiStyleConfig("FaqSection");

	if (!faqs[0]) {
		return null;
	}

	return (
		<React.Fragment>
			<FAQPageJsonLd
				mainEntity={faqs.flatMap((faq) =>
					faq.questions.map((question) => ({
						questionName: question.question,
						acceptedAnswerText: question.answer,
					})),
				)}
			/>
			<chakra.div __css={styles.wrapper}>
				<Container maxW="container.lg">
					<chakra.div __css={styles.titleContainer}>
						{title && <Hx sx={styles.title}>{title}</Hx>}
						{subtitle && (
							<chakra.p __css={styles.subtitle}>
								{subtitle}
							</chakra.p>
						)}
					</chakra.div>
					{faqs.length > 1 ? (
						<Tabs variant="faq">
							<TabList>
								{faqs.map(({ id, title }) => (
									<Tab key={id}>{title}</Tab>
								))}
							</TabList>
							<TabPanels>
								{faqs.map((faq) => (
									<TabPanel key={faq.id}>
										<QuestionsAndAnswers faq={faq} />
									</TabPanel>
								))}
							</TabPanels>
						</Tabs>
					) : (
						<QuestionsAndAnswers faq={faqs[0]} />
					)}
				</Container>
			</chakra.div>
		</React.Fragment>
	);
};

// 🔬 TBD: Please evaluate
