import { ChevronRightIcon } from "@chakra-ui/icons";
import { VStack, chakra, useMultiStyleConfig } from "@chakra-ui/react";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import {
	InlineLinkButtonFragment,
	InlineModalButtonFragment,
} from "src/__generated__/client.codegen";
import { AuthGuard, AuthGuardProps } from "src/components/AuthGuard/AuthGuard";
import { getIcon } from "src/components/CMSButton/CMSButtonLabel";
import { CMSLinkButton } from "src/components/CMSButton/CMSLinkButton";
import { Hx } from "src/components/Heading/Heading";
import { URLModalButton } from "src/components/URLModal/URLModalButton";
import { ModalRoutes } from "src/components/URLModal/modalRoutes";

type UserActionSectionProps = {
	userActions: Array<InlineLinkButtonFragment | InlineModalButtonFragment>;
	headline?: string;
};

type UserActionCardProps = {
	action: InlineLinkButtonFragment | InlineModalButtonFragment;
};

const authMap: Partial<
	Record<keyof ModalRoutes, AuthGuardProps["accessLevels"]>
> = {
	UserData: ["customer"],
	PremiumCalc: ["guest"],
	ForgotPassword: ["guest"],
	Login: ["guest"],
};

const UserActionCard: React.FC<UserActionCardProps> = ({ action }) => {
	const { __typename, leftIcon, label, text, ...rest } = action;

	const styles = useMultiStyleConfig("UserActionSection", {
		variant: __typename === "InlineLinkButtonRecord" ? "link" : "modal",
	});

	const icon = leftIcon
		? getIcon(leftIcon.name, {
				w: "2.125rem",
				h: "2.125rem",
			})
		: undefined;

	const children = (
		<>
			{icon && (
				<chakra.div __css={styles.iconLeftWrapper}>{icon}</chakra.div>
			)}
			<chakra.div __css={styles.textWrapper}>
				<chakra.span __css={styles.label}>{label}</chakra.span>
				{text && <chakra.span __css={styles.text}>{text}</chakra.span>}
			</chakra.div>
			<ChevronRightIcon sx={styles.iconRight} className="chevron-right" />
		</>
	);

	if (__typename === "InlineLinkButtonRecord") {
		return (
			<CMSLinkButton {...action} sx={styles.card}>
				{children}
			</CMSLinkButton>
		);
	}

	const modalProps = {
		...omitBy(
			{
				...rest,
				enablePrefill: undefined,
				analyticsId: undefined,
				linkStyle: undefined,
			},
			isNil,
		),
	};

	return (
		<>
			<URLModalButton
				// we don't want some props on the URLModalButton
				{...modalProps}
				id={action.analyticsId}
				modalName={action.modalName as keyof ModalRoutes}
				sx={styles.card}
			>
				{children}
			</URLModalButton>
		</>
	);
};

export const UserActionSection: React.FC<UserActionSectionProps> = ({
	headline,
	userActions,
}) => {
	const styles = useMultiStyleConfig("UserActionSection");
	return (
		<>
			<VStack sx={styles.wrapper}>
				<Hx size="h2" color="white">
					{headline}
				</Hx>
				<chakra.div __css={styles.cardsWrapper}>
					{userActions.map((action) => {
						const modalName =
							"modalName" in action && action.modalName
								? (action.modalName as keyof ModalRoutes)
								: undefined;

						return (
							<AuthGuard
								key={action.id}
								accessLevels={
									(modalName && authMap[modalName]) ?? [
										"guest",
									]
								}
							>
								<UserActionCard action={action} />
							</AuthGuard>
						);
					})}
				</chakra.div>
			</VStack>
		</>
	);
};

// 🔬 TBD
